import React, { useContext } from "react";
import { FormContext } from "../../FormContext";

import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
// import Multiselect from "react-bootstrap-multiselect";
// import MultiSelect from "react-bootstrap-multiselect";
import Multiselect from "multiselect-react-dropdown";

const MultipleSelect = ({
  field_id,
  field_label,
  field_value,
  field_options,
}) => {
  const { handleChange } = useContext(FormContext);
  const onSelect = (selectedList, selectedItem) => {
    handleChange(field_id, selectedList.map((i) => i.id).toString());
  };

  const onRemove = (selectedList, removedItem) => {
    handleChange(field_id, selectedList.map((i) => i.id).toString());
  };
  return (
    <div className="mb-3 mt-3">
      <label
        htmlFor="exampleMultiEmail1"
        className="form-label"
        style={{
          fontWeight: "bold",
        }}
      >
        {field_label}
      </label>

      <Multiselect
        options={field_options.map((i) => ({ name: i, id: i }))} // Options to display in the dropdown
        // selectedValues={field_value} // Preselected value to persist in dropdown
        onSelect={onSelect} // Function will trigger on select event
        onRemove={onRemove} // Function will trigger on remove event
        displayValue="name" // Property name to display in the dropdown options
        showArrow
        placeholder=""
      />
      {/* <DropdownMultiselect
        id="exampleMultiEmail1"
        options={field_options}
        name={field_label}
        label={field_label}
        onChange={(event) => handleChange(field_id, event)}
      /> */}
      {/* <input
        type="checkbox"
        className="form-check-input"
        id="exampleCheck1"
        checked={field_value}
        onChange={(event) => handleChange(field_id, event)}
      />
      <label className="form-check-label" htmlFor="exampleCheck1">
        {field_label}
      </label> */}
    </div>
  );
};

export default MultipleSelect;
