import React, { useContext } from "react";
import { FormContext } from "../../FormContext";
const Select = ({
  field_id,
  field_label,
  field_placeholder,
  field_value,
  field_options,
}) => {
  const { handleChange } = useContext(FormContext);

  return (
    <div className="mb-3 mt-3">
      <label
        className="form-label"
        style={{
          fontWeight: "bold",
        }}
      >
        {field_label}
      </label>
      <select
        className="form-select"
        aria-label="Default select example"
        onChange={(event) => handleChange(field_id, event)}
        // multiple
      >
        <option>Not selected</option>
        {field_options.length > 0 &&
          field_options.map((option, i) => (
            <option value={option} key={i}>
              {option}
            </option>
          ))}
      </select>
    </div>
  );
};

export default Select;
