import formJSON from "./test.json";
import { useState, useEffect } from "react";
import Element from "./components/Element";
import { FormContext } from "./FormContext";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  CardContent,
  Card,
  Typography,
  Select,
  InputLabel,
  FormControl,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Input,
  OutlinedInput,
} from "@mui/material";
import { collection, setDoc, doc, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import "./styles.css";

function App() {
  const [elements, setElements] = useState(null);
  const [formValues, setFormValues] = useState([
    {
      name: "",
      relation: "",
      gender: "",
      age: "",
      educfinish: "",
      educurrent: "",
      marstatus: "",
      mainbusiness: "",
      sidebusiness: "",
      avgyincom: "",
      phymendeffct: "",
      cvaccine: "",
    },
  ]);

  let handleInputChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        name: "",
        relation: "",
        gender: "",
        age: "",
        educfinish: "",
        educurrent: "",
        marstatus: "",
        mainbusiness: "",
        sidebusiness: "",
        avgyincom: "",
        phymendeffct: "",
        cvaccine: "",
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  useEffect(() => {
    setElements(formJSON[0]);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);
  const { fields, page_label, survey_properties } = elements ?? {};
  const handleSubmit = async (event) => {
    event.preventDefault();
    let finalObj = elements.fields.reduce(
      (a, v) => ({ ...a, [v.field_id]: v.field_value }),
      {}
    );
    if (finalObj["૧.૧ કુટુંબના વડાનું નામ:"]) {
      finalObj = [JSON.stringify(finalObj)];

      let responseObj = await addDoc(collection(db, "data2"), {
        answers: finalObj,
        famalies: JSON.stringify(formValues),
      });

      window.location.reload();
    } else {
      alert("Please enter required field");
    }
  };
  const handleChange = (id, event) => {
    const newElements = { ...elements };
    newElements.fields.forEach((field) => {
      const { field_type, field_id } = field;
      if (id === field_id) {
        switch (field_type) {
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "multiple-select":
            field["field_value"] = event;
            break;
          default:
            field["field_value"] = event.target.value;
            break;
        }
      }
      setElements(newElements);
    });
  };
  return (
    <FormContext.Provider value={{ handleChange }}>
      <div
        style={{
          background: "#1976d2",
          color: "#fff",
          padding: 20,
          display: "flex",
        }}
        className="text-center"
      >
        <img src="/logo.png" height={90} />
        <h3
          className="text-center"
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 10,
          }}
          // style={{
          //   background: "#1976d2",
          //   color: "#fff",
          //   padding: 20,
          // }}
        >
          {survey_properties?.intro_message1}
        </h3>
      </div>
      <div className="App container" style={{ padding: 10 }}>
        <form>
          {fields
            ? fields.map((field, i) => <Element key={i} field={field} />)
            : null}

          <Card
            style={{
              boxShadow:
                "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 80px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
              marginTop: 10,
            }}
          >
            <CardContent>
              <Typography
                style={{
                  marginBottom: 30,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {/* {myData.survey_properties.intro_message3} */}
              </Typography>

              {/* <Grid container spacing={2}> */}
              {/* {form2Data.map((key, ind) => (
              <Grid key={ind} item xs={4}>
                {getFormElement(key)}
              </Grid>
            ))} */}
              {/* <MultipleFields /> */}

              {formValues.map((element, index) => (
                <div className="form-inline" key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"નામ"}
                        >
                          નામ
                        </InputLabel>
                        <OutlinedInput
                          id="નામ"
                          label="નામ"
                          type="text"
                          name="name"
                          style={{ width: "100%" }}
                          value={element.name || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={4} style={{ display: "flex" }}>
              <label>નામ</label>
              <input
                type="text"
                name="name"
                style={{ width: "100%" }}
                value={element.name || ""}
                onChange={(e) => handleInputChange(index, e)}
              />
            </Grid> */}
                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"વડા સાથેનો સંબંધ"}
                        >
                          વડા સાથેનો સંબંધ
                        </InputLabel>
                        <OutlinedInput
                          id="વડા સાથેનો સંબંધ"
                          label="વડા સાથેનો સંબંધ"
                          type="text"
                          name="relation"
                          style={{ width: "100%" }}
                          value={element.relation || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"લિંગ"}
                        >
                          લિંગ
                        </InputLabel>
                        <OutlinedInput
                          id="લિંગ"
                          label="લિંગ"
                          type="text"
                          name="gender"
                          style={{ width: "100%" }}
                          value={element.gender || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"ઉંમર(પૂર્ણ કરેલ વર્ષ)"}
                        >
                          ઉંમર(પૂર્ણ કરેલ વર્ષ)
                        </InputLabel>
                        <OutlinedInput
                          id="ઉંમર(પૂર્ણ કરેલ વર્ષ)"
                          label="ઉંમર(પૂર્ણ કરેલ વર્ષ)"
                          type="text"
                          name="age"
                          style={{ width: "100%" }}
                          value={element.age || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"શિક્ષણ પૂર્ણ"}
                        >
                          શિક્ષણ પૂર્ણ
                        </InputLabel>
                        <OutlinedInput
                          id="શિક્ષણ પૂર્ણ"
                          label="શિક્ષણ પૂર્ણ"
                          type="text"
                          name="educfinish"
                          style={{ width: "100%" }}
                          value={element.educfinish || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"શિક્ષણ અભ્યાસ ચાલુ"}
                        >
                          શિક્ષણ અભ્યાસ ચાલુ
                        </InputLabel>
                        <OutlinedInput
                          id="શિક્ષણ અભ્યાસ ચાલુ"
                          label="શિક્ષણ અભ્યાસ ચાલુ"
                          type="text"
                          name="educurrent"
                          style={{ width: "100%" }}
                          value={element.educurrent || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"વૈવાહિક દરજ્જો"}
                        >
                          વૈવાહિક દરજ્જો
                        </InputLabel>
                        <OutlinedInput
                          id="વૈવાહિક દરજ્જો"
                          label="વૈવાહિક દરજ્જો"
                          type="text"
                          name="marstatus"
                          style={{ width: "100%" }}
                          value={element.marstatus || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"વ્યવસાય મુખ્ય"}
                        >
                          વ્યવસાય મુખ્ય
                        </InputLabel>
                        <OutlinedInput
                          id="વ્યવસાય મુખ્ય"
                          label="વ્યવસાય મુખ્ય"
                          type="text"
                          name="mainbusiness"
                          style={{ width: "100%" }}
                          value={element.mainbusiness || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"વ્યવસાય ગૌણ"}
                        >
                          વ્યવસાય ગૌણ
                        </InputLabel>
                        <OutlinedInput
                          id="વ્યવસાય ગૌણ"
                          label="વ્યવસાય ગૌણ"
                          type="text"
                          name="sidebusiness"
                          style={{ width: "100%" }}
                          value={element.sidebusiness || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"સરેરાશ માસિક આવક"}
                        >
                          સરેરાશ માસિક આવક
                        </InputLabel>
                        <OutlinedInput
                          id="સરેરાશ માસિક આવક"
                          label="સરેરાશ માસિક આવક"
                          type="text"
                          name="avgyincom"
                          style={{ width: "100%" }}
                          value={element.avgyincom || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"શારિ/માન. ખોડખાપણ"}
                        >
                          શારિ/માન. ખોડખાપણ
                        </InputLabel>
                        <OutlinedInput
                          id="શારિ/માન. ખોડખાપણ"
                          label="શારિ/માન. ખોડખાપણ"
                          type="text"
                          name="phymendeffct"
                          style={{ width: "100%" }}
                          value={element.phymendeffct || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel
                          style={{
                            fontWeight: "bold",
                          }}
                          htmlFor={"કોરોનાની વેક્સીન લીધેલ છે. હા/ના"}
                        >
                          કોરોનાની વેક્સીન લીધેલ છે. હા/ના
                        </InputLabel>
                        <OutlinedInput
                          id="કોરોનાની વેક્સીન લીધેલ છે. હા/ના"
                          label="કોરોનાની વેક્સીન લીધેલ છે. હા/ના"
                          type="text"
                          name="cvaccine"
                          style={{ width: "100%" }}
                          value={element.cvaccine || ""}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      {index ? (
                        <Button
                          type="button"
                          variant="contained"
                          className="button remove"
                          onClick={() => removeFormFields(index)}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                  <hr />
                </div>
              ))}
              <div className="button-section">
                <Button
                  className="button add"
                  type="button"
                  variant="contained"
                  onClick={() => addFormFields()}
                >
                  Add
                </Button>
                {/* <Button className="button submit" type="submit">
              Submit
            </Button> */}
              </div>
              {/* </Grid> */}
            </CardContent>
          </Card>

          <div className="text-center mt-2">
            <button
              type="button"
              style={{
                width: "20%",
              }}
              className="btn btn-primary "
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </FormContext.Provider>
  );
}

export default App;
